import {
  JournalActionsType,
  SET_JOURNAL,
  SET_JOURNALS
} from './actions';
import {
  IJournalInitialState,
  IJournalActionHandler,
  IJournalDialogue
} from './interfaces';

const JournalInitialState: IJournalInitialState = {
  journals: [],
  journal: null
};

const setJournals = ({
  state,
  payload
}: IJournalActionHandler): IJournalInitialState => {
  return {
    ...state,
    journals: payload as IJournalDialogue[]
  };
};

const setJournal = ({
  state,
  payload
}: IJournalActionHandler): IJournalInitialState => {
  return {
    ...state,
    journal: payload as IJournalDialogue
  };
};

const journalReducerHandlers = new Map([
  [SET_JOURNALS, setJournals],
  [SET_JOURNAL, setJournal]
]);

const journalReducer = (state = JournalInitialState, action: JournalActionsType): IJournalInitialState => {
  const handler = journalReducerHandlers.get(action.type);

  return (handler && {
    ...handler(<IJournalActionHandler>{
      state,
      payload: action.payload
    })
  }) || {...state};
};

export default journalReducer;
