import {GritxToastOptions} from './GritxToastOptions';
import {ToastKey} from './ToastKeyEnum';

const toastOptionsMap: Record<ToastKey, GritxToastOptions> = {
  [ToastKey.GetUserData]:
    {title: 'gritx.toasts.auth.wrongUserUpdate', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.GetBotAvatars]:
    {title: 'gritx.toasts.auth.wrongBotAvatar', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.GetUserAvatars]:
    {title: 'gritx.toasts.auth.wrongUserAvatar', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.UpdateUserProfileAction]:
    {title: 'gritx.toasts.auth.wrongProfileUpdate', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.LoadContentAction]:
    {title: 'gritx.toasts.wrongLoadContent', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.GetLibFilters]:
    {title: 'gritx.toasts.wrongLoadContent', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.LoadArticleAction]:
    {title: 'gritx.toasts.wrongLoadContent', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.LoadMaterialsContentAction]:
    {title: 'gritx.toasts.wrongLoadContent', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.AddFavorite]:
    {title: 'gritx.toasts.content.wrongAddFavoriteCard', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.RemoveFavorite]:
    {title: 'gritx.toasts.content.wrongRemoveFavoriteCard', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.AddMaterial]:
    {title: 'gritx.toasts.content.wrongNotAbleCard', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.RemoveMaterial]:
    {title: 'gritx.toasts.content.wrongRemoveCard', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.LoadBackpackContentAction]:
    {title: 'gritx.toasts.content.wrongLoadBackpack', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.LoadMaterialsTemplates]:
    {title: 'gritx.toasts.wrongLoadContent', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.SendMaterialsAction]:
    {title: 'gritx.toasts.wrongCompleteAction', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.SaveJournalAction]:
    {title: 'gritx.toasts.wrongCompleteAction', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.UploadAttachAction]:
    {title: 'gritx.toasts.wrongCompleteAction', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.SendFeedbackAction]:
    {title: 'gritx.toasts.wrongCompleteAction', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.JoinNewsletterAction]:
    {title: 'gritx.toasts.wrongCompleteAction', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.LoadTranslation]:
    {title: 'gritx.toasts.translation.wrongLoad', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.ChangeLocale]:
    {title: 'gritx.toasts.translation.wrongChange', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.GetToken]:
    {title: 'gritx.toasts.chatBot.wrongSomething', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.FetchMedia]:
    {title: 'gritx.toasts.wrongLoadContent', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.GetBotList]:
    {title: 'gritx.toasts.chatBot.wrongSomething', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.GetMainDialog]:
    {title: 'gritx.toasts.wrongLoadContent', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.SetDialogLanguage]:
    {title: 'gritx.toasts.chatBot.wrongLanguageChange', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.GetXpeditionList]:
    {title: 'gritx.toasts.wrongLoadContent', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.StartXpedition]:
    {title: 'gritx.toasts.wrongCompleteAction', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.StartInterview]:
    {title: 'gritx.toasts.wrongCompleteAction', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.LoadDialoguesAction]:
    {title: 'gritx.toasts.wrongLoadContent', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.SetDialogueNameAction]:
    {title: 'gritx.toasts.wrongCompleteAction', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.LoadDialogueAction]:
    {title: 'gritx.toasts.wrongLoadContent', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.CreateDialogueAction]:
    {title: 'gritx.toasts.wrongCompleteAction', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.RemoveDialogueAction]:
    {title: 'gritx.toasts.wrongCompleteAction', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.ResumeDialogue]:
    {title: 'gritx.toasts.wrongCompleteAction', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.LoadDialogueParticipantsAction]:
    {title: 'gritx.toasts.wrongLoadContent', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.LoadDialogueParticipantAction]:
    {title: 'gritx.toasts.wrongLoadContent', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.LoadBotsAction]:
    {title: 'gritx.toasts.wrongLoadContent', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.LoadUsersAction]:
    {title: 'gritx.toasts.wrongLoadContent', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.AddBotToDialogueAction]:
    {title: 'gritx.toasts.wrongCompleteAction', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.AddUsersToDialogueAction]:
    {title: 'gritx.toasts.wrongCompleteAction', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.RemoveParticipantFromDialogueAction]:
    {title: 'gritx.toasts.wrongCompleteAction', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.BlockParticipantAction]:
    {title: 'gritx.toasts.wrongCompleteAction', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.UnlockParticipantAction]:
    {title: 'gritx.toasts.wrongCompleteAction', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.CreateVideoCallAction]:
    {title: 'gritx.toasts.wrongCompleteAction', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.GetVideoCallTokenAction]:
    {title: 'gritx.toasts.wrongCompleteAction', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.GetVideoCallRtmTokenAction]:
    {title: 'gritx.toasts.wrongCompleteAction', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.FinishVideoCallAction]:
    {title: 'gritx.toasts.wrongCompleteAction', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.GetVideoCallActiveAction]:
    {title: 'gritx.toasts.wrongLoadContent', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.GetDialogueHistory]:
    {title: 'gritx.toasts.wrongLoadContent', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.StartXpeditionAction]:
    {title: 'gritx.toasts.wrongLoadContent', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.RestartXpeditionAction]:
    {title: 'gritx.toasts.wrongLoadContent', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.LoadXpeditionsAction]:
    {title: 'gritx.toasts.wrongLoadContent', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.LoadTeiArticleAction]:
    {title: 'gritx.toasts.wrongLoadContent', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.LoadTeiArticleAuthorsAction]:
    {title: 'gritx.toasts.wrongLoadContent', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.LoadTeiArticleReferencesAction]:
    {title: 'gritx.toasts.wrongLoadContent', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.LoadTeiArticleReferenceAction]:
    {title: 'gritx.toasts.wrongLoadContent', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.LoadTeiReferenceAuthorsAction]:
    {title: 'gritx.toasts.wrongLoadContent', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.LoadTeiArticleSectionAction]:
    {title: 'gritx.toasts.wrongLoadContent', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.LoadTeiArticleSectionsAction]:
    {title: 'gritx.toasts.wrongLoadContent', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.GetResourceFilters]:
    {title: 'gritx.toasts.wrongLoadContent', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.LoadJournalsAction]:
    {title: 'gritx.toasts.wrongLoadContent', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.CreateJournalAction]:
    {title: 'gritx.toasts.wrongCompleteAction', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.EditJournalAction]:
    {title: 'gritx.toasts.wrongCompleteAction', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true},
  [ToastKey.RemoveJournalAction]:
    {title: 'gritx.toasts.wrongCompleteAction', description: 'gritx.toasts.descriptionUpdate', showReloadButton: true}
};

export const getToastOption = (key: ToastKey): GritxToastOptions => toastOptionsMap[key];
