import axios, {AxiosResponse} from 'axios/index';
import {IJournalDialogue} from '../journal/interfaces';

export const getJournalsApi = (): Promise<AxiosResponse<IJournalDialogue[]>> => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/xpedition/journal/list`
  );
};

export const createJournalApi = (journal: IJournalDialogue, lang: string): Promise<AxiosResponse<{ value: string }>> => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/xpedition/journal/add`,
    {
      name: journal.dialogName,
      userDate: journal.dialogUserDate,
      language: lang
    }
  );
};

export const editJournalApi = (journal: IJournalDialogue): Promise<AxiosResponse<{ value: string }>> => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/xpedition/journal/edit`,
    {
      id: journal.dialogId,
      name: journal.dialogName,
      userDate: journal.dialogUserDate
    }
  );
};
