import React, {useState} from 'react';
import ModalWindow from '../modal-window';
import {useIntl} from 'react-intl';
import {ButtonVariant, GritxButton} from '@wholesalechange/chatcomponent';
import Input from '../input';

import './style.scss';
import {IJournalDialogue} from '../../redux/journal/interfaces';
import InputDate from '../input-date';
import {InputTime} from '../input-time/InputTime';

interface IJournalModalWindow {
  journalCard: IJournalDialogue
  title: string
  onSave: (journal: IJournalDialogue) => void
  onClose: () => void
  show: boolean
}

export const JournalModalWindow = (
  {
    journalCard,
    title,
    onSave,
    onClose,
    show
  }: IJournalModalWindow
): JSX.Element => {
  const intl = useIntl();
  const [journal, setJournal] = useState<IJournalDialogue>(journalCard);

  function handleClose() {
    setJournal(journalCard);
    onClose();
  }

  const getValidFormStatus = (): boolean => {
    return !!journal.dialogName && journal.dialogName.trim() !== '' && !!journal.dialogUserDate;
  };

  function handleSave() {
    if (getValidFormStatus()) {
      onSave(journal);
    }
  }

  function handleChange(type: string): (e: string) => void {
    return (e) => {
      const newJournal = {
        ...journal,
        [type]: e
      };

      setJournal(newJournal);
    };
  }

  function handleChangeDate(type: string): (e: Date) => void {
    return (e) => {
      const newJournal = {
        ...journal,
        [type]: e
      };

      setJournal(newJournal);
    };
  }

  function handleChangeTime(type: string): (e: Date) => void {
    return (e) => {
      const newJournal = {
        ...journal,
        [type]: e
      };

      setJournal(newJournal);
    };
  }

  const content = () => {
    return <div className={'journal-form__container'}>
      <div className={'form-journal'}>
        <div className="form-journal__header">
          <h2 className="form-journal__title">{title}</h2>
        </div>
        <div className="form-journal__body">
          <Input
            required
            type="text"
            value={journal.dialogName}
            label={intl.formatMessage({
              id: 'gritx.journal.name.label',
              defaultMessage: 'Name'
            })}
            onChange={handleChange('dialogName')}
          />

          <InputDate
            required
            value={journal.dialogUserDate}
            label={intl.formatMessage({
              id: 'gritx.journal.date.label',
              defaultMessage: 'Time'
            })}
            onChange={handleChangeDate('dialogUserDate')}
          />

          <InputTime
            required
            value={journal.dialogUserDate}
            label={intl.formatMessage({
              id: 'gritx.journal.time.label',
              defaultMessage: 'Time'
            })}
            onChange={handleChangeTime('dialogUserDate')}
          />
        </div>
      </div>
    </div>;
  };

  const formsButtons = () => {
    return <div className="journal-form__footer">
      <GritxButton
        title={intl.formatMessage({
          id: 'gritx.common.button.cancel',
          defaultMessage: 'Cancel'
        })}
        variant={ButtonVariant.Light}
        className="journal-form__button"
        onClick={handleClose}
      />
      <GritxButton
        title={intl.formatMessage({
          id: 'gritx.common.button.save',
          defaultMessage: 'Save'
        })}
        variant={ButtonVariant.Primary}
        className="journal-form__button"
        onClick={handleSave}
      />
    </div>;
  };

  return <ModalWindow
    show={show}
    className="journal-form__modal"
    onHide={handleClose}
    footerElement={formsButtons()}
  >
    {content()}
  </ModalWindow>;
};
