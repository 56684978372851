import {IJournalDialogue} from './interfaces';
import {IAction} from '../interface';
import {InferActionsTypes} from '../helpers';

export const SET_JOURNALS = 'SET_JOURNALS';
export const setJournals = (journals: IJournalDialogue[]): IAction<IJournalDialogue[]> => ({
  type: SET_JOURNALS,
  payload: journals
});

export const SET_JOURNAL = 'SET_JOURNAL';
export const setJournal = (journal: IJournalDialogue): IAction<IJournalDialogue> => ({
  type: SET_JOURNAL,
  payload: journal
});

const journalActions = {
  setJournals,
  setJournal
};

export type JournalActionsType = InferActionsTypes<typeof journalActions>
