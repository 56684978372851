export enum ToastKey {
  GetUserData,
  GetBotAvatars,
  GetUserAvatars,
  UpdateUserProfileAction,
  LoadContentAction,
  GetLibFilters,
  LoadArticleAction,
  LoadMaterialsContentAction,
  AddFavorite,
  RemoveFavorite,
  AddMaterial,
  RemoveMaterial,
  LoadBackpackContentAction,
  LoadMaterialsTemplates,
  SendMaterialsAction,
  SaveJournalAction,
  UploadAttachAction,
  SendFeedbackAction,
  JoinNewsletterAction,
  LoadTranslation,
  ChangeLocale,
  GetToken,
  FetchMedia,
  GetBotList,
  GetMainDialog,
  SetDialogLanguage,
  GetXpeditionList,
  StartXpedition,
  StartInterview,
  LoadDialoguesAction,
  SetDialogueNameAction,
  LoadDialogueAction,
  CreateDialogueAction,
  RemoveDialogueAction,
  ResumeDialogue,
  LoadDialogueParticipantsAction,
  LoadDialogueParticipantAction,
  LoadBotsAction,
  LoadUsersAction,
  AddBotToDialogueAction,
  AddUsersToDialogueAction,
  RemoveParticipantFromDialogueAction,
  BlockParticipantAction,
  UnlockParticipantAction,
  StartXpeditionAction,
  RestartXpeditionAction,
  CreateVideoCallAction,
  GetVideoCallTokenAction,
  GetVideoCallRtmTokenAction,
  FinishVideoCallAction,
  GetVideoCallActiveAction,
  GetDialogueHistory,
  LoadXpeditionsAction,
  LoadTeiArticleAction,
  LoadTeiArticleAuthorsAction,
  LoadTeiArticleReferencesAction,
  LoadTeiArticleSectionsAction,
  LoadTeiArticleReferenceAction,
  LoadTeiReferenceAuthorsAction,
  GetResourceFilters,
  LoadTeiArticleSectionAction,
  LoadJournalsAction,
  CreateJournalAction,
  EditJournalAction,
  RemoveJournalAction
}
